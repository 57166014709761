import axios from 'axios'
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers.post['x-api-key'] = 'nq09nGJhl271qpYjRHK0l4km5kO0HaZ26eKcS8u1'
const BACKEND_URL = 'https://95gt5aswvl.execute-api.us-east-1.amazonaws.com/dev/'
const BASE_URL = 'https://www.entr.ca'

const app = {
    state: {
        days: 0
    },
    initialize: () => {
        const image = app.getParameterByName('imageUrl')
        const title = app.getParameterByName('title')
        if (title && image) {
            $(".marketpace-optional").css('display', 'block')
            $(".marketplace-title").text(title)
            $(".marketplace-image").attr('src', image)
        }
        $("#datepicker").datepicker();
        $('.money').mask('000,000,000,000,000.00', { reverse: true });

        $('.remove').on('click', app.removeExtendedDay)
        $(".extend").on('click', app.createExtendedDay)
        $('.form-wrapper').submit(app.submitForm)
        $('.other').on('click', () => {
            if ($('.otherTextArea').css('display') === 'block') {
                $('.otherTextArea').css('display', 'none')
                $('.otherTextArea').val('')
            } else {
                $('.otherTextArea').css('display', 'block')
            }
        })
        var token = app.getParameterByName('short_code')
        $('.hidden').val(token)
    },
    getParameterByName: (name) => {
        const url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    renderExtendedDays: () => {
        $('.extendedDays').empty()
        for (let i = 0; i < app.state.days; i++) {
            const input = $('<input class="input">')
            input.attr('id', "date-" + i)
            input.attr('name', "date-" + i)
            input.appendTo('.extendedDays')
            input.datepicker()
            input.wrap('<div class="row' + ' day' + i + '"><div class="col-12 col-sm-4 col-md-4"></div></div>')

            if (i >= 1) {
                const newDate = $('#date-' + (i - 1)).datepicker('getDate')
                newDate.setDate(newDate.getDate() + 1)
                $("#date-" + i).datepicker("setDate", newDate);
            } else {
                const newDate = $('#datepicker').datepicker('getDate')
                newDate.setDate(newDate.getDate() + 1)
                $("#date-" + i).datepicker("setDate", newDate);
            }

            let prevValue = $('#start').val()
            const cloneStartTime = $('#start').clone()
            const cloneEndTime = $('#end').clone()

            cloneStartTime.attr('id', 'startTime-' + i)
            cloneStartTime.attr('name', 'startTime-' + i)
            cloneEndTime.attr('id', 'endTime-' + i)
            cloneEndTime.attr('name', 'endTime-' + i)
            cloneStartTime.appendTo('.day' + i)
            cloneEndTime.appendTo('.day' + i)


            $('#startTime-' + i).wrap('<div class="col-6 col-sm-4 col-md-4"></div>')
            $('#endTime-' + i).wrap('<div class="col-6 col-sm-4 col-md-4"></div>')
        }
        $('.remove').css('display', 'block')
    },
    createExtendedDay: () => {
        const datepicker = $('#datepicker').val()
        const start = $('#start').val()
        const end = $('#end').val()

        if (datepicker && start && end) {
            app.state.days += 1
            app.renderExtendedDays()
        }
    },
    removeExtendedDay: () => {
        app.state.days -= 1;
        app.renderExtendedDays()

        if (app.state.days === 0) {
            $('.remove').css('display', 'none')
        }
    },
    sendForm: (data) => axios.post(BACKEND_URL, data),
    submitForm: (e) => {
        e.preventDefault();
        const formValues = $(".form-wrapper").serializeArray()
        const formObj = {
            eventTime: {
                extendedDays: {

                }
            }
        }

        for (let i = 0; i < formValues.length; i++) {
            const property = formValues[i].name
            const value = formValues[i].value

            if (property.includes('-')) {
                const attrArr = property.split('-')
                const day = attrArr[1]
                const prop = attrArr[0]

                if (formObj.eventTime.extendedDays[day]) {
                    formObj.eventTime.extendedDays[day] = {
                        ...formObj.eventTime.extendedDays[day],
                        [prop]: value
                    }
                } else {
                    formObj.eventTime.extendedDays = {
                        ...formObj.eventTime.extendedDays,
                        [day]: {
                            [prop]: value
                        }
                    }
                }
            } else if (property === "date" ||
                property === "endTime" ||
                property === "startTime") {
                formObj['eventTime'] = {
                    ...formObj['eventTime'],
                    [property]: value
                }
            } else if (property === 'services') {
                if (!formObj['services']) {
                    formObj['services'] = [value]
                } else {
                    formObj['services'] = [...formObj['services'], value]
                }
            } else {
                formObj[property] = value
            }
        }

        const eventTimes = [{
            date: formObj.eventTime.date,
            startTime: formObj.eventTime.startTime,
            endTime: formObj.eventTime.endTime,
        }, ...Object.values(formObj.eventTime.extendedDays)]

        formObj['eventTime'] = eventTimes
        formObj['space_lead'] = null
        /* Calculate space */
        const space = app.getParameterByName('space')
        const title = app.getParameterByName('title')
        const campaignid = app.getParameterByName('campaignid')
        const mbsy_source = app.getParameterByName('mbsy_source')
        const mbsy = app.getParameterByName('mbsy')
        const mbsy_exp = app.getParameterByName('mbsy_exp')
        const short_code = app.getParameterByName('short_code')
        formObj['campaignid'] = campaignid
        formObj['mbsy_source'] = mbsy_source
        formObj['mbsy'] = mbsy
        formObj['mbsy_exp'] = mbsy_exp
        formObj['short_code'] = short_code
        if (space) {
            formObj['space_lead'] = `${BASE_URL}${space}`
        }
        if (title) {
            formObj['title_space'] = title
        }

        $('.after-sent-error').css('display', 'none')
        $('.after-sent').css('display', 'none')

        app.sendForm(formObj)
            .then(res => {
                $('.before-sent').css('display', 'none')
                $('.after-sent').css('display', 'block')
            })
            .catch(err => {
                $('.before-sent').css('display', 'none')
                $('.after-sent-error').css('display', 'block')
                console.log('something went wrong', err)
            })
    }
}

app.initialize()
